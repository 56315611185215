import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['field']

  connect () {
    if (!this.fieldTarget.value) {
      const currentTime = moment().format('MM/DD/YYYY hh:mm A')
      this.fieldTarget.value = currentTime
    }
  }

  reload () {
    this.disconnect()
    this.connect()
  }
}
